import { GetDatasetMetadataResponse } from '@amzn/fox-den-cost-planning-lambda';
import { ColDef } from 'ag-grid-enterprise';
import { capitalize } from 'lodash';
import { useMemo } from 'react';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';
import {
  getMappingSchemaForDataset,
  getSchemaForDataset,
  IBatchMetadataParsed,
  ITableDataSchemaColumnItem,
} from 'src/utils/planning/batchMetadataModel';
import { PlanTypeId, SupportedDatasetType } from 'src/utils/planning/planetModel';

interface UseDatasetSchemaParams {
  batchMetadata: IBatchMetadataParsed | undefined;
  datasetMetadata?: GetDatasetMetadataResponse | undefined;
  currentDataset: SupportedDatasetType;
  isSsrmDataGrid: boolean;
}

// Header words that have a hardcoded capitalization style
const specialTokens: Record<string, string> = {
  id: 'ID',
  isd: 'ISD',
  su: 'SU',
  re: 'RE',
  car: 'CAR',
  rou: 'ROU',
  aro: 'ARO',
  gl: 'GL',
  sqft: 'sqft',
  capex: 'CapEx',
  ctl: 'CTL',
  npv: 'NPV',
  coa: 'COA',
  tco: 'TCO',
  or: 'or',
  uid: 'UID',
  lsa: 'LSA',
  oem: 'OEM',
  usd: 'USD',
};

const convertFieldToHeader = (field: string) =>
  field
    .toLowerCase()
    .split('_')
    .map((token) => {
      if (token in specialTokens) {
        return specialTokens[token];
      }
      return capitalize(token);
    })
    .join(' ');

// probably need to align with all dataTypes from BE schema
export const getCellDataTypeFromSchema = (schema: ITableDataSchemaColumnItem | undefined) =>
  schema?.dataType === 'NUMBER' ? 'number' : undefined;

export const useDatasetSchema = ({
  batchMetadata,
  datasetMetadata,
  currentDataset,
  isSsrmDataGrid,
}: UseDatasetSchemaParams) => {
  const { data: featureFlags } = useFeatureFlags();

  const schema = useMemo(
    () => getSchemaForDataset(currentDataset, batchMetadata),
    [batchMetadata, currentDataset],
  );

  const mappingSchema = useMemo(
    () => getMappingSchemaForDataset(currentDataset, batchMetadata),
    [batchMetadata, currentDataset],
  );

  const columnDefs = useMemo<ColDef[] | null>(() => {
    if (!schema) return null;

    const enableOrderMapping =
      featureFlags?.feUseColumnMappingSchemaConfig &&
      mappingSchema &&
      mappingSchema.length === schema.length;

    const tableConfig: ColDef[] = schema
      .map((item, index) => {
        const mappingItem = mappingSchema?.find((m) => m.originalName === item.dimensionName);

        const field = item.dimensionName;

        const headerName = featureFlags?.feUseColumnMappingSchemaConfig
          ? convertFieldToHeader(mappingItem?.displayName || item.dimensionName)
          : convertFieldToHeader(item.dimensionName);

        const hide = featureFlags?.feUseColumnMappingSchemaConfig ? mappingItem?.hide : false;

        const cellDataType = getCellDataTypeFromSchema(item);

        const displayOrder = enableOrderMapping
          ? mappingSchema.findIndex((m) => m.originalName === item.dimensionName)
          : index;

        return { field, headerName, hide, cellDataType, displayOrder };
      })
      .sort((a, b) => a.displayOrder - b.displayOrder)
      .map(({ field, headerName, hide, cellDataType }) => {
        const enableFilterParams = datasetMetadata?.filterParams?.[field]?.length;

        const enableNumberFormatter =
          featureFlags?.feValueFormatterTopsDown &&
          PlanTypeId.TOPS_DOWN_FORECAST &&
          cellDataType === 'number';

        return {
          field,
          headerName,
          hide,
          cellDataType,
          enablePivot: !isSsrmDataGrid,
          enableRowGroup: !isSsrmDataGrid,
          enableValue: !isSsrmDataGrid,

          ...(enableFilterParams
            ? {
                filter: 'agSetColumnFilter',
                filterParams: { values: datasetMetadata?.filterParams?.[field] },
              }
            : { filter: !isSsrmDataGrid }),

          ...(enableNumberFormatter
            ? {
                valueFormatter: (params) => {
                  if (params.value === null || params.value === undefined || params.value === '') {
                    return params.value;
                  }
                  const num = Number(params.value);
                  const schemaItem = schema.find((s) => s.dimensionName === field);
                  const formatOptions: Intl.NumberFormatOptions = {
                    maximumFractionDigits: schemaItem?.fractionDigits ?? 20,
                    useGrouping: true,
                  };
                  return new Intl.NumberFormat('en-US', formatOptions).format(num);
                },
              }
            : {}),
        };
      });

    return tableConfig;
  }, [
    schema,
    featureFlags?.feUseColumnMappingSchemaConfig,
    featureFlags?.feValueFormatterTopsDown,
    mappingSchema,
    datasetMetadata,
    isSsrmDataGrid,
  ]);

  return { schema, mappingSchema, columnDefs };
};
