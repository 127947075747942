import {
  BaseExportParams,
  CellSelectionOptions,
  ProcessHeaderForExportParams,
  RowSelectionOptions,
  ToolPanelVisibleChangedEvent,
} from 'ag-grid-community';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineFullscreen } from 'react-icons/ai';
import { BiExport, BiRefresh } from 'react-icons/bi';
import { useParams } from 'react-router';
import { AgGridReact } from 'ag-grid-react';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';
import { usePlanDataset } from 'src/api/query/usePlanDataset';
import { AgGridBaseWrapper } from 'src/common/ag-grid/AgGridBaseWrapper';
import { AG_OUTPUT_PAGINATION_PAGE_SIZE } from 'src/pages/plan-manager-page/plan-output-tab/planOutputConfig';
import { useDatasetSchema } from 'src/utils/planning/useDatasetSchema';
import { PlanTypeId, SupportedDatasetType } from 'src/utils/planning/planetModel';
import { useAgGridResizeColumns } from 'src/common/ag-grid/useAgGridResizeColumns';
import { SelectChangeEvent } from 'src/common/EventType';
import { GridComponentProps } from 'src/pages/commons/plan-views/DatasetTabsViewer';
import DatasetGridContainer, {
  DataGridActionButtonGroups,
} from 'src/pages/commons/plan-views/DatasetGridContainer';
import { useTriggerExtractOutput } from 'src/pages/commons/compute-helpers/useTriggerPlanCompute';
import { useStaticFilterList } from 'src/api/query/useBatchDimensionValues';
import { IFilterList, useFilterComponent } from 'src/pages/commons/plan-filters/useFilterComponent';
import { generateStaticSiteList } from 'src/pages/commons/plan-filters/StaticFilterList';
import { useAgGridCalculatedHeight } from 'src/common/ag-grid/useAgGridCalculatedHeight';
import { useAgGridPivotModeConfig } from 'src/common/ag-grid/useAgGridPivotModeConfig';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';

const PlanOutputDataGrid = ({
  headerText,
  description,
  datasetOptions,
  currentDataset,
  setCurrentDataset,
  currentGridPivotMode,
  setCurrentGridPivotMode,
  currentGridToolPanel,
  setCurrentGridToolPanel,
}: GridComponentProps) => {
  const gridRef = useRef<AgGridReact>(null);

  const containerRef = useRef<HTMLDivElement>(null);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const { t } = useTranslation();

  const { batchId } = useParams();

  const { data: batchMetadata } = useBatchMetadata({ batchId });

  const { data: featureFlags } = useFeatureFlags();

  const planRequiresSiteFilter = batchMetadata?.costType === PlanTypeId.UTR_COST_HC;

  const { data: sitesFilterList, isError: noSitesFilterList } = useStaticFilterList(
    {
      planType: batchMetadata?.costType ?? '',
      subGroup: batchMetadata?.subGroup ?? '',
      region: batchMetadata?.region ?? '',
    },
    { disabled: !batchMetadata || !planRequiresSiteFilter },
  );

  const filterConfig = useMemo(() => {
    const filterInfo: IFilterList = [];
    if (planRequiresSiteFilter) {
      filterInfo.push({
        name: 'site',
        label: 'site_name_filter_label',
        options: noSitesFilterList ? generateStaticSiteList() : sitesFilterList ?? [],
      });
    }

    return filterInfo;
  }, [noSitesFilterList, planRequiresSiteFilter, sitesFilterList]);

  const { filtersValid, filterMap, FilterComponent } = useFilterComponent(filterConfig);

  const { schema, mappingSchema, columnDefs } = useDatasetSchema({
    batchMetadata,
    currentDataset,
    isSsrmDataGrid: false,
  });

  const {
    data: rawData,
    refetch: refetchData,
    error: fetchDataError,
    isFetching: planDatasetIsFetching,
  } = usePlanDataset(
    {
      datasetName: currentDataset ?? '',
      batchId: batchMetadata?.batchId ?? '',
      filterList: filterMap,
    },
    schema,
    {
      disabled: !batchMetadata || !currentDataset || !filtersValid,
      cacheTime: 0,
      staleTime: Infinity,
    },
  );

  useEffect(() => {
    if (!gridRef.current?.api) return;
    gridRef.current?.api.setGridOption('loading', planDatasetIsFetching);
  }, [planDatasetIsFetching]);

  const { triggerExtractOutput, isExtractOutputSupported } = useTriggerExtractOutput(
    batchMetadata?.costType,
  );

  const actionButtonGroups = useMemo<DataGridActionButtonGroups>(() => {
    const handleClickExport = () => {
      /** need to temporarily disable rowSelection to make exporting works properly due to an AG Grid defect */
      const rowSelection = gridRef.current?.api.getGridOption('rowSelection');
      gridRef.current?.api.setGridOption('rowSelection', undefined);
      gridRef.current?.api.exportDataAsExcel();
      gridRef.current?.api.setGridOption('rowSelection', rowSelection);
    };

    const handleClickRefresh = () => {
      if (!batchMetadata?.batchId) return;
      if (!isExtractOutputSupported) refetchData();
      else triggerExtractOutput({ batchId: batchMetadata.batchId });
    };

    const handleClickExpand = () => {
      setIsFullScreen(true);
    };

    return [
      [
        {
          icon: BiExport,
          text: t('export'),
          onClick: handleClickExport,
          disabled: currentGridPivotMode,
        },
        { icon: BiRefresh, text: t('refresh'), onClick: handleClickRefresh },
      ],
      [{ icon: AiOutlineFullscreen, text: t('expand'), onClick: handleClickExpand }],
    ];
  }, [
    batchMetadata?.batchId,
    currentGridPivotMode,
    isExtractOutputSupported,
    refetchData,
    t,
    triggerExtractOutput,
  ]);

  const handleDatasetSelected = (e: SelectChangeEvent) => {
    const newDataset = e.detail.selectedOption.value as SupportedDatasetType;
    if (newDataset !== currentDataset) setCurrentDataset(newDataset);
  };

  const { resizeColumns } = useAgGridResizeColumns(gridRef, containerRef);

  const { gridHeight } = useAgGridCalculatedHeight(containerRef, 65, !!FilterComponent);

  const {
    restorePivotModeConfig,
    onColumnRowGroupChanged,
    onColumnPivotChanged,
    onColumnValueChanged,
    onColumnPivotModeChanged,
  } = useAgGridPivotModeConfig({ setCurrentGridPivotMode });

  const planetDefinitionTableDefaultExportParams: BaseExportParams = {
    allColumns: !featureFlags?.feUseColumnMappingSchemaConfig,
    fileName: currentDataset,
    exportedRows:
      batchMetadata?.costType === PlanTypeId.FIXED_COST_CONSOLIDATION ? 'filteredAndSorted' : 'all',
    processHeaderCallback: (params: ProcessHeaderForExportParams) => {
      const field = params?.column?.getColDef?.().field;
      if (featureFlags?.feUseColumnMappingSchemaConfig && field && mappingSchema) {
        const mappingConfig = mappingSchema.find((config) => config.originalName === field);
        return mappingConfig?.displayName || field;
      }
      return field ?? '';
    },
  };

  const cellSelection = useMemo<CellSelectionOptions>(() => ({ handle: { mode: 'range' } }), []);

  const rowSelection = useMemo<RowSelectionOptions>(() => ({ mode: 'multiRow' }), []);

  const onToolPanelVisibleChanged = useCallback(
    (e: ToolPanelVisibleChangedEvent) => {
      setCurrentGridToolPanel(e.visible ? e.key : undefined);
    },
    [setCurrentGridToolPanel],
  );

  return (
    <DatasetGridContainer
      currentDataset={currentDataset}
      onDatasetSelected={handleDatasetSelected}
      datasetOptions={datasetOptions}
      headerText={headerText}
      description={description}
      actionButtonGroups={actionButtonGroups}
    >
      {FilterComponent}
      <div ref={containerRef}>
        <AgGridBaseWrapper
          gridRef={gridRef}
          rowBuffer={10}
          tooltipShowDelay={1000}
          suppressColumnVirtualisation={true}
          onModelUpdated={resizeColumns}
          onPaginationChanged={resizeColumns}
          onGridReady={restorePivotModeConfig}
          onColumnRowGroupChanged={onColumnRowGroupChanged}
          onColumnPivotChanged={onColumnPivotChanged}
          onColumnValueChanged={onColumnValueChanged}
          onColumnPivotModeChanged={onColumnPivotModeChanged}
          onToolPanelVisibleChanged={onToolPanelVisibleChanged}
          gridHeight={gridHeight}
          isFullScreen={isFullScreen}
          setFullScreen={setIsFullScreen}
          pagination={true}
          paginationPageSize={AG_OUTPUT_PAGINATION_PAGE_SIZE}
          cellSelection={cellSelection}
          rowSelection={rowSelection}
          animateRows={true}
          rowData={filtersValid && !fetchDataError ? rawData?.dataset : []}
          columnDefs={columnDefs}
          defaultExcelExportParams={planetDefinitionTableDefaultExportParams}
          pivotMode={currentGridPivotMode}
          gridToolPanel={currentGridToolPanel}
        />
      </div>
    </DatasetGridContainer>
  );
};

export default PlanOutputDataGrid;
