import { ColDef } from 'ag-grid-enterprise';
import { cloneDeep, isArray, uniqueId } from 'lodash';
import { MutableRefObject } from 'react';
import { safeJsonParse } from 'src/utils/parse';
import { ITableDataSchemaColumnItem } from 'src/utils/planning/batchMetadataModel';

export interface IValidationRuleInfo {
  headers: string[];
  validationFunction: (arg0: any) => boolean;
  messageType?: string;
  message: string;
}

export type IRowValidationRawDataRow = Record<string, string | number>;
export type IRowValidationRow = Record<string, IRowValidationCellData>;

export interface IRowValidationCellData {
  value: any;
  original: any;
  lastValidationSuccess: boolean;
  lastValidationNoWarning?: boolean;
  validationErrors: string[];
}

export type IValidationRowTransformer = (
  rowData: IRowValidationRowWithId,
) => IRowValidationRowWithId;

export interface IRowValidationBusinessCondition {
  relation?: string;
  column?: string;
  function?: string;
  skipifnull?: boolean;
  value: string;
  parameters?: string;
}

export type IRowValidationBusinessConditionList = IRowValidationBusinessCondition[];

export interface IRowValidationRowWithId {
  [x: string]: IRowValidationCellData;
  id: IRowValidationCellData;
}

/**
 *
 * @param usingValidationFramework
 * @param initialDataset Raw table data
 * @param validationRowTransformer Validation row transformer {@link getValidationRowTransformer} to validate formatted data and
 * add error messages when validations fail
 * @param indexedId
 * @returns Standardized and validated data to be used in RowValidation Ag grid
 */
export const standardizeInitialData = (
  usingValidationFramework: boolean | undefined,
  initialDataset: IRowValidationRawDataRow[],
  validationRowTransformer: IValidationRowTransformer,
  indexedId?: MutableRefObject<number>,
) => {
  if (!initialDataset) return [];

  const processData = (row: IRowValidationRawDataRow, idx: number) => {
    const record: IRowValidationRowWithId = {
      id: {
        value: indexedId ? `${idx + indexedId.current}` : uniqueId(),
        original: indexedId ? `${idx + indexedId.current}` : uniqueId(),
        lastValidationSuccess: true,
        ...(usingValidationFramework && {
          lastValidationNoWarning: true,
        }),
        validationErrors: [],
      },
    };

    for (const [k, v] of Object.entries(row)) {
      const val = v === '' ? null : v;

      const newValue: IRowValidationCellData = {
        value: val,
        original: val,
        lastValidationSuccess: true,
        ...(usingValidationFramework && {
          lastValidationNoWarning: true,
        }),
        validationErrors: [],
      };

      record[k] = newValue;
    }
    return validationRowTransformer(record);
  };

  const standardizedDataWithId = initialDataset.map(processData);

  if (indexedId) indexedId.current += standardizedDataWithId.length;

  return standardizedDataWithId;
};

export interface IConditionalValidationBusinessRule {
  ruleType?: string;
  conditions: IRowValidationBusinessConditionList;
  messageType?: string;
  message: string;
}

export type IConditionalValidationBusinessRuleList = IConditionalValidationBusinessRule[];

const notNullTypeguard = <T>(val: T | null): val is T => val !== null;

/**
 * @param initialBusinessRules Conditional validation business rules required to validate a row of data
 * @param columnDefs
 * @param schema Data Schema from BE
 * @description Creates business rules for validating Required fields and fields with special types
 * and combines them with provided business rules to return a complete business rules list
 */
const generateFullBusinessRulesList = (
  initialBusinessRules: IConditionalValidationBusinessRuleList,
  columnDefs: ColDef[],
  schema: ITableDataSchemaColumnItem[],
): IConditionalValidationBusinessRuleList => {
  const isRequiredRules: IConditionalValidationBusinessRuleList = schema
    .filter((dimension) => dimension.required)
    .map((dimension) => {
      for (const { field, headerName } of columnDefs) {
        if (dimension.dimensionName === field) {
          return {
            conditions: [
              {
                column: field,
                relation: '=',
                value: 'null',
              },
            ],
            message: `${headerName} is required`,
          };
        }
      }
      return null;
    })
    .filter(notNullTypeguard);

  const VALID_INT_OR_FLOAT =
    '^(([\\+-]?\\d+(\\.\\d+)?)|(([\\+-]?\\d+(\\.\\d+)?)[Ee]{1}([\\+-]?\\d+)))$';
  const VALID_POSITIVE_PERCENTAGE_REGEX = '^([1-9][0-9]?|100)$';
  const VALID_DATE_REGEX = '^\\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|[3][01])$';

  const typeCheckRules: IConditionalValidationBusinessRuleList = schema
    .filter((dimension) => !!dimension.dataType)
    .map((dimension) => {
      let regexVal = null;
      let errorMessageAppend = '';
      if (dimension.dataType === 'NUMBER') {
        regexVal = VALID_INT_OR_FLOAT;
        errorMessageAppend = 'a number.';
      }
      if (dimension.dataType === 'PERCENTAGE') {
        regexVal = VALID_POSITIVE_PERCENTAGE_REGEX;
        errorMessageAppend = 'between (0, 100].';
      }
      if (dimension.dataType === 'DATE') {
        regexVal = VALID_DATE_REGEX;
        errorMessageAppend = 'a date(YYYY-MM-DD).';
      }

      if (regexVal === null) {
        return null;
      }

      for (const { field, headerName } of columnDefs) {
        if (dimension.dimensionName === field) {
          return {
            conditions: [
              {
                column: field,
                relation: 'regexfail',
                skipifnull: true,
                value: regexVal,
              },
            ],
            message: `${headerName} can only be ${errorMessageAppend}`,
          };
        }
      }
      return null;
    })
    .filter(notNullTypeguard);

  const fullBusinessRules = [...typeCheckRules, ...isRequiredRules, ...initialBusinessRules];

  return fullBusinessRules;
};

export type RowValidationValueGetter = (rowData: IRowValidationRow) => any;

/**
 *
 * @param condition
 * @param validHeaders
 * @description Takes in a business condition, parses logic for grabbing the column value required as
 * the "left-hand" value for condition comparisons, and returns a fast accessor function so that value can be
 * grabbed instantly during validations
 * @returns A fast data accessor function that takes in a row of data and quickly returns the column value
 * necessary to check the condition.
 */
const generateColumnValueGetter = (
  condition: IRowValidationBusinessCondition,
  validHeaders: string[],
): RowValidationValueGetter => {
  const valueText = condition.column;

  if (valueText && validHeaders.includes(valueText)) {
    if (condition.function === 'len') {
      return (rowData) => {
        const val = rowData[valueText]?.value ?? null;
        if (!val) {
          return 0;
        }
        return val.length;
      };
    }

    if (condition.function === 'sum') {
      return (rowData) => {
        const parameters: string[] = JSON.parse(condition.parameters ?? '[]');
        return parameters.reduce((sum, colName) => {
          const val = rowData[colName]?.value;
          return sum + (Number(val) || 0);
        }, 0);
      };
    }

    if (condition.function === 'isInteger') {
      return (rowData) => {
        const val = rowData[valueText]?.value ?? null;
        if (!val) {
          return 1;
        }
        const number = Number(val);
        if (Number.isInteger(number) && val === number.toString()) {
          return 1;
        }
        return 0;
      };
    }

    if (condition.function === 'containsCharacters') {
      return (rowData) => {
        const val = rowData[valueText]?.value ?? null;
        if (!val) {
          return 'false';
        }
        const parameters = JSON.parse(condition.parameters ?? '[]');
        for (const element of parameters) {
          if (val.indexOf(element) !== -1) {
            return 'true';
          }
        }
        return 'false';
      };
    }

    return (rowData) => {
      const val = rowData[valueText]?.value?.toString().toLowerCase() ?? null;
      if (!val) {
        return val;
      }

      if (
        val.includes('-') ||
        condition.relation === 'regexfail' ||
        condition.relation === 'notin' ||
        condition.relation === 'in'
      ) {
        return val;
      }
      try {
        const num = parseFloat(val);
        return Number.isNaN(num) ? val : num;
      } catch {
        return val;
      }
    };
  }

  return (_) => '';
};

const nullFunc = (_: IRowValidationRow) => null;

/**
 *
 * @param condition
 * @param validHeaders
 * @description Takes in a business condition, parses what kind of value that the condition requires as
 * the "right-hand" value for comparisons, and returns a fast accessor function so that value can be
 * grabbed instantly during validations
 * @returns A fast data accessor function that takes in a row of data and quickly returns the value
 * necessary to check the condition.
 */
const generateValueGetter = (
  condition: IRowValidationBusinessCondition,
  validHeaders: string[],
): RowValidationValueGetter => {
  const nullPattern = /^null$/;
  const numberPattern = /^([0-9.-]+)$/;
  const columnPattern = /^\$(.+)$/;
  const listPattern = /^\[(.+)$/;

  if (!condition.value || condition.relation === 'regexfail') {
    return nullFunc;
  }
  const valueText = condition.value.toLowerCase();
  if (condition.function === 'len') {
    try {
      const val = parseFloat(valueText);
      return (_) => val;
    } catch {
      return (_) => 0;
    }
  }

  const nullMatch = valueText.match(nullPattern);

  if (nullMatch) {
    return nullFunc;
  }

  const numberPatternMatch = valueText.match(numberPattern);
  if (numberPatternMatch) {
    try {
      const parsedNumber = parseFloat(numberPatternMatch[1]);
      return (_) => parsedNumber;
    } catch (e) {
      console.error(e);
    }
  }

  const columnPatternMatch = valueText.match(columnPattern);

  if (columnPatternMatch) {
    if (validHeaders.includes(columnPatternMatch[1])) {
      return (rowData) => {
        const val = rowData[columnPatternMatch[1]]?.value ?? null;
        if (!val) {
          return val;
        }

        if (val.includes('-')) {
          return val.toString().toLowerCase();
        }
        try {
          const num = parseFloat(val);
          return Number.isNaN(num) ? val : num;
        } catch {
          return val.toString().toLowerCase();
        }
      };
    }
  }

  const listPatternMatch = valueText.match(listPattern);

  if (listPatternMatch) {
    try {
      const optionList = safeJsonParse(valueText)[1];
      if (isArray(optionList) && optionList.some((item) => typeof item !== 'string')) {
        const formattedOptionList = optionList.map((item: any) => item.toString().toLowerCase());
        return (_) => formattedOptionList;
      }
      return (_) => optionList;
    } catch {
      return (_) => [];
    }
  }

  return (_) => valueText;
};

export type RowValidationConditionEvaluator = (rowData: IRowValidationRow) => boolean;

const errorFunc = (_rowData: IRowValidationRow) => false;

/**
 * @param condition A single business condition {@link IRowValidationBusinessCondition}
 * @param validHeaders List of headers valid for the table that this validation will run on
 * @description Creates a fast function to test if a business condition is met. Precomputes parsing
 * and accessing functions so each condition does not need to get parsed while validations are being
 * run on tens of thousands of rows at once.
 * @returns A function which takes in a row of data {@link IRowValidationRow} and returns whether
 * the provided condition is true or false
 */
const getConditionEvaluator = (
  condition: IRowValidationBusinessCondition,
  validHeaders: string[],
): RowValidationConditionEvaluator => {
  if (!condition.relation) {
    console.warn('condition relation is undefined');
    console.warn(cloneDeep(condition));
    return errorFunc;
  }

  if (!condition.column) {
    console.warn('condition column is undefined');
    console.warn(cloneDeep(condition));
    return errorFunc;
  }

  if (!validHeaders.includes(condition.column)) {
    console.warn('condition column is not in valid header list');
    console.warn(cloneDeep(condition));
    console.warn(cloneDeep(validHeaders));
    return errorFunc;
  }

  const columnValueGetter = generateColumnValueGetter(condition, validHeaders);
  const valueGetter = generateValueGetter(condition, validHeaders);

  if (condition.function === 'getMetadata') {
    return errorFunc;
  }

  switch (condition.relation) {
    case 'unique':
    case 'duplicate':
      return (_rowData) => false;

    case 'notin':
      if (!condition.value) {
        console.warn('condition value is undefined');
        console.warn(cloneDeep(condition));
        return errorFunc;
      }
      return (rowData) => {
        const firstValue = columnValueGetter(rowData);
        if (firstValue === null && condition.skipifnull) {
          return false;
        }
        const secondValue = valueGetter(rowData);
        return !secondValue.includes(firstValue);
      };

    case 'in':
      if (!condition.value) {
        console.warn('condition value is undefined');
        console.warn(cloneDeep(condition));
        return errorFunc;
      }
      return (rowData) => {
        const firstValue = columnValueGetter(rowData);
        if (firstValue === null && condition.skipifnull) {
          return false;
        }
        const secondValue = valueGetter(rowData);
        return !!secondValue.includes(firstValue);
      };

    case '=':
      if (!condition.value) {
        console.warn('condition value is undefined');
        console.warn(cloneDeep(condition));
        return errorFunc;
      }
      return (rowData) => {
        const firstValue = columnValueGetter(rowData);
        if (firstValue === null && condition.skipifnull) {
          return false;
        }
        const secondValue = valueGetter(rowData);
        return firstValue === secondValue;
      };

    case '!=':
      if (!condition.value) {
        console.warn('condition value is undefined');
        console.warn(cloneDeep(condition));
        return errorFunc;
      }
      return (rowData) => {
        const firstValue = columnValueGetter(rowData);
        if (firstValue === null && condition.skipifnull) {
          return false;
        }
        const secondValue = valueGetter(rowData);
        return firstValue !== secondValue;
      };

    case '>':
      if (!condition.value) {
        console.warn('condition value is undefined');
        console.warn(cloneDeep(condition));
        return errorFunc;
      }

      return (rowData) => {
        const firstValue = columnValueGetter(rowData);

        if (firstValue === null && condition.skipifnull) {
          return false;
        }
        const secondValue = valueGetter(rowData);
        return firstValue > secondValue;
      };

    case '<':
      if (!condition.value) {
        console.warn('condition value is undefined');
        console.warn(cloneDeep(condition));
        return errorFunc;
      }

      return (rowData) => {
        const firstValue = columnValueGetter(rowData);

        if (firstValue === null && condition.skipifnull) {
          return false;
        }
        const secondValue = valueGetter(rowData);
        return firstValue < secondValue;
      };

    case '>=':
      if (!condition.value) {
        console.warn('condition value is undefined');
        console.warn(cloneDeep(condition));
        return errorFunc;
      }

      return (rowData) => {
        const firstValue = columnValueGetter(rowData);
        if (firstValue === null && condition.skipifnull) {
          return false;
        }
        const secondValue = valueGetter(rowData);
        return firstValue >= secondValue;
      };

    case '<=':
      if (!condition.value) {
        console.warn('condition value is undefined');
        console.warn(cloneDeep(condition));
        return errorFunc;
      }

      return (rowData) => {
        const firstValue = columnValueGetter(rowData);
        if (firstValue === null && condition.skipifnull) {
          return false;
        }
        const secondValue = valueGetter(rowData);
        return firstValue <= secondValue;
      };

    case 'regexfail': {
      if (!condition.value) {
        console.warn('condition value is undefined');
        console.warn(cloneDeep(condition));
        return errorFunc;
      }
      const regex = new RegExp(condition.value);
      return (rowData) => {
        const firstValue = columnValueGetter(rowData);

        if (firstValue === null && condition.skipifnull) {
          return false;
        }
        return !regex.test(firstValue);
      };
    }

    default:
      return (_: any) => false;
  }
};

/**
 *
 * @param conditionList List of business conditions to check
 * @param validHeaders List of valid table headers for the table being validated.
 * @description Memoizes validation logic and returns a function to see
 *  if an error message should be shown.
 * @example
 * const conditionList = [
 *   {
 *     column: 'column1',
 *     operator: '<',
 *     value: '1',
 *   }
 * @returns A function which runs all of the conditions and
 * return false if all of the conditions pass (meaning that the validation failed and the error
 * message should be shown)
 */
const getConditionalValidationEvaluator = (
  conditionList: IRowValidationBusinessConditionList,
  validHeaders: string[],
): RowValidationConditionEvaluator => {
  const conditionEvaluatorList: RowValidationConditionEvaluator[] = [];

  for (let i = 0; i < conditionList.length; i++) {
    conditionEvaluatorList.push(getConditionEvaluator(conditionList[i], validHeaders));
  }

  return (rowData) => {
    for (let i = 0; i < conditionEvaluatorList.length; i++) {
      const evaluatorFunction = conditionEvaluatorList[i];
      if (!evaluatorFunction(rowData)) {
        return true;
      }
    }
    return false;
  };
};

/**
 *
 * @param businessRules All conditional validation business rules required to validate a row of data
 * @param columnDefs
 * @param schema Data Schema from BE
 * @param useColumnMappingSchema
 * @param usingValidationFramework
 * @description Creates a validation function for each business rule using {@link getConditionalValidationEvaluator}
 * and associates it with the rule's provided error message and columns referenced within the rule
 * @example
 * const businessRules = [
 *   {
 *     message: 'Error message',
 *     conditions: [
 *       {
 *         column: 'column1',
 *         operator: '<',
 *         value: '1',
 *       }
 *     ]
 *   }
 * @returns A list of validation rules {@link IValidationRuleInfo} with information about related columns and
 * error message to be shown on those columns
 */
const createValidationRuleList = (
  businessRules: IConditionalValidationBusinessRuleList,
  columnDefs: ColDef[],
  schema: ITableDataSchemaColumnItem[],
  useColumnMappingSchema: boolean,
  usingValidationFramework: boolean,
) => {
  const validHeaders = schema.map((item) => item.dimensionName);
  const validationRuleList: IValidationRuleInfo[] = [];

  const fullBusinessRules = generateFullBusinessRulesList(businessRules, columnDefs, schema);

  const fieldToHeaderMap = new Map(columnDefs.map((col) => [col.field, col.headerName]));

  for (const { message, conditions, ruleType, messageType } of fullBusinessRules) {
    if (!conditions) continue;

    if (
      ['WindowColumnSum', 'ReferentialIntegrity', 'EmptyDataset', 'NonEmptyDataset'].includes(
        ruleType ?? '',
      )
    ) {
      continue;
    }

    const conditionalEvaluator = getConditionalValidationEvaluator(conditions, validHeaders);
    const headerList = [];
    for (const condition of conditions) {
      if (condition.column) {
        headerList.push(condition.column);
      }
    }

    let displayMessage = message;
    if (useColumnMappingSchema) {
      // Replace column names in the message with their display names
      for (const header of headerList) {
        const displayName = fieldToHeaderMap.get(header) || header;
        displayMessage = displayMessage.replace(new RegExp(header, 'g'), displayName);
      }
    }

    validationRuleList.push({
      headers: headerList,
      validationFunction: conditionalEvaluator,
      message: displayMessage,
      ...(usingValidationFramework && messageType && { messageType }),
    });
  }

  return validationRuleList;
};

/**
 *
 * @param businessRules All conditional validation business rules required to validate a row of data
 * @param columnDefs
 * @param schema Data Schema from BE
 * @param useColumnMappingSchema
 * @param usingValidationFramework
 * @description Takes in a list of business rules and uses them to create a validation function
 * which takes in a row of {@link IRowValidationRow} and
 * returns that row with appropriate error messages added to the row items
 * @example
 * const businessRules = [
 *   {
 *     message: 'Error message',
 *     conditions: [
 *       {
 *         column: 'column1',
 *         operator: '<',
 *         value: '1',
 *       }
 *     ]
 *   }
 * @returns A validation function which takes in a row of {@link IRowValidationRow} and
 * returns that row with appropriate error messages added to the row items
 */
export const getValidationRowTransformer = (
  businessRules: IConditionalValidationBusinessRuleList | undefined,
  columnDefs: ColDef[] | undefined,
  schema: ITableDataSchemaColumnItem[] | undefined,
  useColumnMappingSchema: boolean,
  usingValidationFramework: boolean,
) => {
  if (!businessRules || !columnDefs) return null;

  const validationRuleList = createValidationRuleList(
    businessRules,
    columnDefs,
    schema || [],
    useColumnMappingSchema,
    usingValidationFramework,
  );

  const validationRowTransformer: IValidationRowTransformer = (
    rowData: IRowValidationRowWithId,
  ) => {
    for (const key of Object.keys(rowData)) {
      if (key === 'id') {
        continue;
      }

      rowData[key].lastValidationSuccess = true;
      if (usingValidationFramework) {
        rowData[key].lastValidationNoWarning = true;
      }
      rowData[key].validationErrors = [];
    }

    for (const validationRuleInfo of validationRuleList) {
      if (!validationRuleInfo.validationFunction(rowData)) {
        for (const header of validationRuleInfo.headers) {
          if (!(header in rowData)) {
            console.warn(header + ' not valid header');
            continue;
          }
          if (usingValidationFramework && validationRuleInfo.messageType === 'warning') {
            rowData[header].lastValidationNoWarning = false;
          } else {
            rowData[header].lastValidationSuccess = false;
          }
          if (!rowData[header].validationErrors.includes(validationRuleInfo.message)) {
            rowData[header].validationErrors.push(validationRuleInfo.message);
          }
        }
      }
    }

    return rowData;
  };

  return validationRowTransformer;
};
