import {
  Flashbar,
  ProgressBar,
  Box,
  Button,
  Modal,
  SpaceBetween,
  FileDropzone,
  FileTokenGroup,
  Alert,
} from '@amzn/awsui-components-react/polaris';
import {
  GetDatasetMetadataResponse,
  UploadBatchEditsRequest,
  UploadBatchEditsResponse,
} from '@amzn/fox-den-cost-planning-lambda';
import { RefObject, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { UseMutateFunction } from '@tanstack/react-query';
import { AgGridReact } from 'ag-grid-react';
import {
  S3_COST_PLANNING_BUCKET_WRITE_ONLY,
  S3_PLANET_BUCKET_WRITE_ONLY,
  configStorage,
  configRegion,
  putInS3,
} from 'src/utils/s3';
import { FileInput } from 'src/common/FileInput';
import { useNotificationContext } from 'src/hooks/useNotificationContext';
import { useFeatureFlags } from 'src/api/query/useFeatureFlags';
import {
  getLastUpdatedOnOfDataset,
  IMappingSchemaColumnItem,
} from 'src/utils/planning/batchMetadataModel';
import { PlanTypeId, SupportedDatasetType } from 'src/utils/planning/planetModel';
import { RequestVariables } from 'src/api/api-utils';
import { FileDropzoneChangeEvent } from 'src/common/EventType';
import { IUsePlanDatasetMetadata } from 'src/api/query/usePlanDataset';
import { useBatchUpsertDatasets } from 'src/api/query/useBatchDimensionValues';
import { OperationType } from 'src/api/mutation/useUploadEditsMutation';
import { useBatchMetadata } from 'src/api/query/useBatchMetadata';

interface UploadDatasetModalProps {
  visible: boolean;
  currentDataset: SupportedDatasetType;
  currentDatasetLabel: string | undefined;
  isUpdatingDataset: boolean;
  datasetMetadata: IUsePlanDatasetMetadata | GetDatasetMetadataResponse | undefined;
  validHeaders: string[] | null | undefined;
  mappingSchema: IMappingSchemaColumnItem[] | undefined;
  gridRef: RefObject<AgGridReact>;
  onClose: () => void;
  uploadDatasetMutationMethod: UseMutateFunction<
    UploadBatchEditsResponse,
    unknown,
    RequestVariables<UploadBatchEditsRequest>,
    unknown
  >;
}

/**
 * Common component for handling file upload
 */
const UploadDatasetModal = ({
  visible,
  currentDataset,
  currentDatasetLabel,
  isUpdatingDataset,
  datasetMetadata,
  validHeaders,
  mappingSchema,
  gridRef,
  onClose,
  uploadDatasetMutationMethod,
}: UploadDatasetModalProps) => {
  const { t } = useTranslation();
  const { batchId } = useParams();
  const { data: batchMetadata } = useBatchMetadata({ batchId });
  const { data: featureFlags } = useFeatureFlags();

  const { addNotificationForApiError } = useNotificationContext();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);

  const { data: upsertDatasets } = useBatchUpsertDatasets(
    { planType: (batchMetadata?.costType as PlanTypeId) ?? '' },
    { disabled: !batchId },
  );

  const fileUploadOperation = useMemo(
    () => (upsertDatasets?.includes(currentDataset) ? OperationType.UPSERT : OperationType.REPLACE),
    [currentDataset, upsertDatasets],
  );

  const handleFileSelect = (file: File | null) => {
    setSelectedFile(file);
  };

  const handleFileDrop = ({ detail }: FileDropzoneChangeEvent) => {
    if (!featureFlags?.feDropFile) return;
    const file = detail.value[0];
    setSelectedFile(file);
  };

  const handleClose = () => {
    setProgressPercentage(0);
    setSelectedFile(null);
    onClose();
  };

  const handleSubmit = async () => {
    if (!selectedFile || !batchMetadata || !datasetMetadata || !validHeaders) return;

    try {
      setIsUploadingFile(true);
      // currently only UTR Productivity supports us east bucket
      const bucket =
        featureFlags?.utrUSEastBucket && batchMetadata.costType === PlanTypeId.UTR_PRODUCTIVITY
          ? S3_PLANET_BUCKET_WRITE_ONLY
          : S3_COST_PLANNING_BUCKET_WRITE_ONLY;

      const fileParts = selectedFile.name.split('.');
      const fileType = fileParts[fileParts.length - 1];
      const fileName = `${currentDataset}/${new Date().getTime()}.${fileType}`;
      const s3Path = '';

      configStorage(s3Path, bucket);
      configRegion(bucket);

      await putInS3(fileName, selectedFile, selectedFile.type, (progress: number) => {
        setProgressPercentage(progress);
      });

      uploadDatasetMutationMethod({
        batchId: batchMetadata.batchId ?? '',
        batchEditsFile: { bucket, key: s3Path + fileName },
        headers: validHeaders,
        datasetType: currentDataset,
        lastUpdatedOn: getLastUpdatedOnOfDataset(batchMetadata, currentDataset),
        partitionS3VersionMap: datasetMetadata.partitionS3VersionMap ?? null,
        partitionLocator: datasetMetadata.partitionLocator ?? null,
        operation: fileUploadOperation,
      });

      handleClose();
    } catch (error: any) {
      addNotificationForApiError({ content: t('api_error_upload_file_s3'), error });
    } finally {
      setIsUploadingFile(false);
    }
  };

  const handleDownloadTemplate = useCallback(() => {
    /** temporarily disable rowSelection to make exporting works properly due to an AG Grid defect */
    const rowSelection = gridRef.current?.api.getGridOption('rowSelection');
    gridRef.current?.api.setGridOption('rowSelection', undefined);

    /** export template file */
    gridRef.current?.api.exportDataAsExcel({
      allColumns: !featureFlags?.feUseColumnMappingSchemaConfig,
      fileName: currentDataset + '_template',
      exportedRows: 'all',
      processHeaderCallback: (params) => {
        const field = params?.column?.getColDef?.().field;
        if (featureFlags?.feUseColumnMappingSchemaConfig && field && mappingSchema) {
          const mappingConfig = mappingSchema.find((config) => config.originalName === field);
          return mappingConfig?.displayName || field;
        }
        return field ?? '';
      },
      shouldRowBeSkipped: (_params: any) => true,
    });

    /** after exporting, restore rowSelection configuration */
    gridRef.current?.api.setGridOption('rowSelection', rowSelection);
  }, [currentDataset, featureFlags?.feUseColumnMappingSchemaConfig, gridRef, mappingSchema]);

  return (
    <Modal
      size="large"
      header={`${t('import')}: ${currentDatasetLabel}`}
      onDismiss={handleClose}
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={handleClose} variant="link">
              {t('cancel')}
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={!selectedFile}
              loading={isUpdatingDataset || isUploadingFile}
              loadingText={t('updating')}
              variant="primary"
            >
              {t('import')}
            </Button>
          </SpaceBetween>
        </Box>
      }
      visible={visible}
    >
      <SpaceBetween size="xl">
        {progressPercentage ? (
          <Flashbar
            items={[
              {
                type: 'info',
                content: (
                  <ProgressBar
                    label={t('uploading_file_label')}
                    value={progressPercentage}
                    variant="flash"
                  />
                ),
              },
            ]}
          />
        ) : null}

        <FileInput
          form={{
            accept: '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            description: t('choose_csv_xlsx_to_upload'),
            label: t('select_file_label'),
            constraint: t('file_select_same_columns_constraint'),
          }}
          file={selectedFile}
          onChange={handleFileSelect}
        />

        {featureFlags?.feDropFile ? (
          <FileDropzone onChange={handleFileDrop}>{t('drop_file_label')}</FileDropzone>
        ) : null}

        {selectedFile ? (
          <FileTokenGroup
            items={[{ file: selectedFile }]}
            onDismiss={() => {
              setSelectedFile(null);
            }}
          />
        ) : null}

        <SpaceBetween size="xl">
          <Alert type="warning">
            {fileUploadOperation === OperationType.UPSERT
              ? t('import_upsert_dataset_warning')
              : t('import_replace_dataset_warning')}
          </Alert>
          <Button onClick={handleDownloadTemplate}>{t('download_template')}</Button>
        </SpaceBetween>
      </SpaceBetween>
    </Modal>
  );
};

export default UploadDatasetModal;
